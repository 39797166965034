var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "门店信息",
          isChildLevelIds: true,
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.shopDeptTreeData,
        },
        on: { allNodeIds: _vm.allNodeIds },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isAdvancedSearch: false,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入门店编码、名称、助记码",
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "单据信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c("operatingButton", {
                      attrs: {
                        getList: _vm.getList,
                        isRecycleBtn: true,
                        isDeleteBtn: false,
                        multiple: _vm.multiple,
                      },
                      on: {
                        handleAdd: _vm.handleAdd,
                        handleDelete: _vm.handleDelete,
                        handleRedoDelete: function ($event) {
                          _vm.dialogOptions.show = true
                        },
                      },
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              "tooltip-effect": "dark",
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                              "default-sort": {
                                prop: "sortNo",
                                order: "ascending",
                              },
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                type: "index",
                                width: "80",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopNo",
                              attrs: {
                                align: "center",
                                prop: "shopNo",
                                label: "门店编码",
                                "show-overflow-tooltip": "",
                                width: "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.shopNo) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "shopName",
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                "show-overflow-tooltip": "",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopTypeName",
                              attrs: {
                                label: "门店类型",
                                align: "center",
                                prop: "shopTypeName",
                                "show-overflow-tooltip": "",
                                width: "100",
                              },
                            }),
                            _c("el-table-column", {
                              key: "useArea",
                              attrs: {
                                label: "门店面积",
                                align: "center",
                                prop: "useArea",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              key: "linkEmployeeName",
                              attrs: {
                                label: "联系人",
                                align: "center",
                                prop: "linkEmployeeName",
                                "show-overflow-tooltip": "",
                                width: "120",
                              },
                            }),
                            _c("el-table-column", {
                              key: "linkEmployeeTel",
                              attrs: {
                                label: "联系电话",
                                align: "center",
                                prop: "linkEmployeeTel",
                                "show-overflow-tooltip": "",
                                width: "140",
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopAddress",
                              attrs: {
                                label: "门店地址",
                                align: "center",
                                prop: "shopAddress",
                                width: "200",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              key: "zjm",
                              attrs: {
                                label: "助记码",
                                align: "center",
                                prop: "zjm",
                                width: "120",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "sortNo",
                                align: "center",
                                label: "排序",
                                "show-overflow-tooltip": "",
                                sortable: true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "remark",
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                "min-width": "120",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "updateBy",
                              attrs: {
                                label: "修改人",
                                align: "center",
                                prop: "updateBy",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改时间",
                                align: "center",
                                prop: "updateTime",
                                width: "160",
                                sortable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              key: "createBy",
                              attrs: {
                                label: "创建人",
                                align: "center",
                                prop: "createBy",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建时间",
                                align: "center",
                                prop: "createTime",
                                width: "160",
                                sortable: "",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            idsCom: _vm.ids,
                            totalCom: _vm.total,
                          },
                          on: { selectAllCom: _vm.selectAll },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 800, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.form,
                      rules: _vm.rules,
                      "label-width": "120px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "门店名称", prop: "shopId" } },
                          [
                            _c("treeselect", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                disabled:
                                  _vm.form.shopNo == "0000" &&
                                  _vm.form.createTime,
                                options: _vm.shopTreeOptions,
                                "show-count": true,
                                placeholder: "请选择门店",
                                normalizer: _vm.normalizer,
                                "disable-branch-nodes": true,
                                size: "mini",
                              },
                              on: { input: _vm.inputSelect },
                              model: {
                                value: _vm.form.shopId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "shopId", $$v)
                                },
                                expression: "form.shopId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "门店编码", prop: "shopNo" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                disabled:
                                  _vm.form.shopNo == "0000" &&
                                  _vm.form.createTime,
                                placeholder: "请输入门店编码",
                                maxlength: "20",
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.shopNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "shopNo", $$v)
                                },
                                expression: "form.shopNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _vm.shopType == 1
                          ? _c(
                              "el-form-item",
                              {
                                attrs: { label: "门店类型", prop: "shopType" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "inputWidth",
                                    attrs: {
                                      placeholder: "门店类型",
                                      filterable: "",
                                      clearable: "",
                                      size: "mini",
                                    },
                                    model: {
                                      value: _vm.form.shopType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "shopType", $$v)
                                      },
                                      expression: "form.shopType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dict.type.shop_type,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: "门店类型" } },
                              [
                                _c("el-input", {
                                  staticClass: "inputWidth",
                                  attrs: {
                                    type: "text",
                                    disabled: "",
                                    placeholder: "弱加盟店",
                                    size: "mini",
                                  },
                                }),
                              ],
                              1
                            ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "零售价格方案",
                              prop: "priceCaseId",
                            },
                          },
                          [
                            _c("SelectRemote", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                option: _vm.$select({
                                  key: "listPriceCase",
                                  option: {
                                    option: {
                                      showItem: [
                                        {
                                          priceCaseId: _vm.form.priceCaseId,
                                          priceCaseName: _vm.form.priceCaseName,
                                        },
                                      ],
                                    },
                                  },
                                }).option,
                              },
                              model: {
                                value: _vm.form.priceCaseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "priceCaseId", $$v)
                                },
                                expression: "form.priceCaseId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "排序", prop: "sortNo" } },
                          [
                            _c("el-input-number", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "排序",
                                "controls-position": "right",
                                min: 1,
                              },
                              model: {
                                value: _vm.form.sortNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "sortNo", $$v)
                                },
                                expression: "form.sortNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "调拨价格方案",
                              prop: "movePriceCaseId",
                            },
                          },
                          [
                            _c("SelectRemote", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                option: _vm.$select({
                                  key: "listPriceCase",
                                  option: {
                                    option: {
                                      showItem: [
                                        {
                                          priceCaseId: _vm.form.movePriceCaseId,
                                          priceCaseName:
                                            _vm.form.movePriceCaseName,
                                        },
                                      ],
                                    },
                                  },
                                }).option,
                              },
                              model: {
                                value: _vm.form.movePriceCaseId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "movePriceCaseId", $$v)
                                },
                                expression: "form.movePriceCaseId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "联系人", prop: "linkEmployeeId" },
                          },
                          [
                            _c("SelectRemote", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                option: _vm.$select({
                                  key: "listEmployee",
                                  option: {
                                    option: {
                                      showItem: [
                                        {
                                          employeeId: _vm.form.linkEmployeeId,
                                          employeeName:
                                            _vm.form.linkEmployeeName,
                                        },
                                      ],
                                    },
                                  },
                                }).option,
                              },
                              model: {
                                value: _vm.form.linkEmployeeId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "linkEmployeeId", $$v)
                                },
                                expression: "form.linkEmployeeId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "联系电话",
                              prop: "linkEmployeeTel",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                type: "text",
                                placeholder: "请输入联系电话",
                                maxlength: "20",
                                size: "mini",
                              },
                              model: {
                                value: _vm.form.linkEmployeeTel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "linkEmployeeTel", $$v)
                                },
                                expression: "form.linkEmployeeTel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "门店电话", prop: "shopTel" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入门店电话",
                                maxlength: "20",
                              },
                              model: {
                                value: _vm.form.shopTel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "shopTel", $$v)
                                },
                                expression: "form.shopTel",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "门店面积", prop: "useArea" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                placeholder: "请输入门店面积",
                                maxlength: "6",
                              },
                              model: {
                                value: _vm.form.useArea,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "useArea", $$v)
                                },
                                expression: "form.useArea",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "门店地址", prop: "regionId" } },
                          [
                            _c("el-cascader", {
                              ref: "tree",
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                options: _vm.provinceList,
                                props: _vm.defaultProp,
                                maxlength: "80",
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.regionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "regionId", $$v)
                                },
                                expression: "form.regionId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "详细地址", prop: "regionAddress" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                size: "mini",
                                type: "text",
                                placeholder: "详细地址",
                                maxlength: "80",
                              },
                              model: {
                                value: _vm.form.regionAddress,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "regionAddress", $$v)
                                },
                                expression: "form.regionAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 2, maxRows: 4 },
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini" },
                    on: { click: _vm.closeDia },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.saveAndAdd },
                  },
                  [_vm._v("保存并新增")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v("保存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }